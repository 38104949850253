import styles from "./styles";
import { IButtonProps } from "./types";

export const Button = ({
  text,
  type = "button"
}: IButtonProps) => {
  return (
    <button css={styles.ButtonWrapper} type={type}>
      {text}
    </button>
  );
};