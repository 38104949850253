import { css } from "@emotion/react";

const styles = {
  FinancesWrapper: css`
    padding: 2rem;
  `,
  GoalDebtWrapper: css`
    display: flex;
    position: relative;

    > span {
      position: absolute;
      right: 0;

      // TODO Bring in the media queries from Wallwins site
      @media (min-width: 768px) {
        right: 50%;
      }
    }
  `,
  SummaryWrapper: css`
    position: relative;
    display: flex;
    flex-direction: column;

    h3 {
      margin: 0;
    }
    span {
      position: absolute;
      right: 0;

      @media (min-width: 768px) {
        right: 50%;
      }
    }
  `,
  NoMarginBottom: css`
    margin-bottom: 0;
  `,
  NoMargin: css`
    h2 {
      margin: 0;
    }
  `,
  Excess: css`
  
  `,
};

export default styles;