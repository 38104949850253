import { Button } from "../Button";
import { Input } from "../Input";
import styles from "./styles";

export const Login = () => {
  return (
    <div css={styles.LoginWrapper}>
      <form>
        <Input
          label="Username"
          name="username"
          placeholder="Username"
        />

        <Input
          label="Password"
          name="password"
          placeholder="Password"
          type="password"
        />

        <Button
          text="Login"
        />
      </form>
    </div>
  );
};