import { GoalRealm, IYearlyGoal } from "../../types";

export interface ITwentyTwentyTwoGoals {
  [GoalRealm.PHYSICAL]: Array<IYearlyGoal>;
  [GoalRealm.MENTAL]: Array<IYearlyGoal>;
  [GoalRealm.FINANCIAL]: Array<IYearlyGoal>;
  [GoalRealm.EMOTIONAL]: Array<IYearlyGoal>;
  [GoalRealm.PRACTICAL]: Array<IYearlyGoal>;
  [GoalRealm.PROFESSIONAL]: Array<IYearlyGoal>;
  [GoalRealm.RELATIONSHIP]: Array<IYearlyGoal>;
  [GoalRealm.SPIRITUAL]: Array<IYearlyGoal>;
  [key: string]: Array<IYearlyGoal>;
}

export const TwentyTwentyTwoGoals = {
  [GoalRealm.PHYSICAL] : [
    {
      goal: "Join the 1000lbs club",
      achieved: false,
      realm: GoalRealm.PHYSICAL
    },
    {
      goal: "Run a half marathon",
      achieved: false,
      realm: GoalRealm.PHYSICAL
    },
  ],
  [GoalRealm.FINANCIAL] : [
    {
      goal: "Baby Steps 1, 2 & 3",
      achieved: true,
      realm: GoalRealm.FINANCIAL
    }
  ],
  [GoalRealm.PROFESSIONAL] : [
    {
      goal: "Complete Microfocus probation",
      achieved: true,
      realm: GoalRealm.PROFESSIONAL
    },
    {
      goal: "Build Wallwins site",
      achieved: false,
      realm: GoalRealm.PROFESSIONAL
    },
    {
      goal: "Build Michaels site",
      achieved: false,
      realm: GoalRealm.PROFESSIONAL
    },
  ],
  [GoalRealm.MENTAL] : [
    {
      goal: "Read one book a month for the remainder of the year (May - Dec, 8 books)",
      achieved: true,
      realm: GoalRealm.MENTAL
    },
    {
      goal: "Complete Grade 2 in violin",
      achieved: true,
      realm: GoalRealm.MENTAL
    },
    {
      goal: "Complete Grade 3 in violin",
      achieved: false,
      realm: GoalRealm.MENTAL
    },
  ],
  [GoalRealm.SPIRITUAL] : [
    {
      goal: "Meditate every day (track on calendar)",
      achieved: false,
      realm: GoalRealm.SPIRITUAL
    },
  ],
  [GoalRealm.PRACTICAL] : [
    {
      goal: "Fix the side gate",
      achieved: true,
      realm: GoalRealm.PRACTICAL
    },
    {
      goal: "Sand & paint the fence",
      achieved: true,
      realm: GoalRealm.PRACTICAL
    }
  ],
  [GoalRealm.EMOTIONAL] : [
    {
      goal: "Practice reflection each week for 20 minutes",
      achieved: false,
      realm: GoalRealm.EMOTIONAL
    },
  ],
  [GoalRealm.RELATIONSHIP] : []
};
