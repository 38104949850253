export const babySteps = [
  {
    step: "Emergeny fund of at least £1000",
    achieved: true
  },
  {
    step: "Clear down all debt except the mortgage",
    achieved: true
  },
  {
    step: "3-6 months of expenses",
    achieved: true
  },
  {
    step: "Increase pension contributions to 15%",
    achieved: true
  },
  {
    step: "Save for university fund",
    achieved: false,
  },
  {
    step: "Pay off the mortgage early",
    achieved: false,
  },
  {
    step: "Build wealth and give",
    achieved: false
  }
];