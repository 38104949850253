export const theme = {
  colors: {
    paradisePink: '#ef476f',
    crayolaYellow: '#FFD166',
    caribbeanGreen: '#06D6A0',
    blueNCS: '#118AB2',
    midnightGreen: '#073B4C',
    white: '#fff'
  },
  animationTiming: {
    intro: 3500
  }
};