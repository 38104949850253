import { useState } from "react";
import styles from "./styles";
import { IGoalProps } from "./types";

export const Goal = ({
  goal,
  date,
  isChecked = false,
  handleCheck,
  showCheck = true
}: IGoalProps) => {
  const [checked, setChecked] = useState<boolean>(isChecked);

  const handleOnClick = () => {
    if (handleCheck) {
      handleCheck(goal);
    }
    setChecked(!checked);
  };

  return (
    <div css={styles.GoalWrapper}>
      {showCheck && <div css={[styles.Checkbox, styles.Checked(checked)]} onClick={handleOnClick} />}
      <span css={styles.Goal(checked)}>{goal}</span>
      <span css={styles.Date}>{date}</span>
    </div>
  );
};