import styles from "./styles";
import { IInputProps } from "./types";

export const Input = ({
  label,
  name,
  placeholder,
  type = "text"
}: IInputProps) => {
  return (
    <div css={styles.InputWrapper}>
      <label css={styles.Label} htmlFor={name}>{label}</label>
      <input type={type} name={name} placeholder={placeholder} />
    </div>
  );
};