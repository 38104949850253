import { useState } from "react";
import { IOutgoing, IYearlyFinancialGoal } from "../../shared/types";
import { TwentyTwoFinancialGoals } from "../../shared/utils/2022/financial-goals";
import { babySteps } from "../../shared/utils/baby-steps";
import { MonthlyOutgoings } from "../../shared/utils/monthly-outgoings";
import { formatDate } from "../../shared/utils/utils";
import { Goal } from "../Goal";
import { Title } from "../Title";
import styles from "./styles";

export const Finances = () => {
  const [twentyTwoGoals, setTwentyTwoGoals] = useState<Array<IYearlyFinancialGoal>>(TwentyTwoFinancialGoals);
  const [outgoings] = useState<Array<IOutgoing>>(MonthlyOutgoings);

  const calculateDebt = () => {
    return twentyTwoGoals.reduce((partialSum, d) => {
      return !d.achieved ? partialSum + d.debt : partialSum
    }, 0);
  };

  const calculateTotalOutgoings = (): number => {
    return outgoings.reduce((partialSum, o) => {
      return o.amount + partialSum;
    }, 0);
  };

  const calculateSaving = (): number => {
    return (salary + sarahsContribution) - totalOutgoings;
  };

  const calculatedStillToPay = (): number => {
    return outgoings.reduce((partialSum, o) => {
      return o.date > new Date().getDate() ? partialSum + o.amount : partialSum;
    }, 0);
  };

  const [, setDebt] = useState(calculateDebt());
  const [salary] = useState<number>(3261.84);
  const [sarahsContribution] = useState<number>(450);
  // 4,077.62 pm without any pension contributions
  // 3,873.73 pm with 5% pension
  // 3,465.97 pm with 15% pension contribution (Baby Step 4)
  // 3,261.84 Songtradr pay

  const [totalOutgoings] = useState<number>(calculateTotalOutgoings());
  const [savings] = useState<number>(calculateSaving());
  const [stillToPay] = useState<number>(calculatedStillToPay());

  const handleCheck = (goal: string) => {
    const index = twentyTwoGoals.findIndex((t) => t.goal === goal);

    if (index !== -1) {
      const found = twentyTwoGoals[index];
      found.achieved = !found.achieved;

      const arr = twentyTwoGoals;
      arr.splice(index, 1, found);

      setDebt(calculateDebt());
      setTwentyTwoGoals(arr);
    }
  };

  // Returns £1280
  // const calculateLoanRepayment = (): number => {
  //   return TwentyTwoFinancialGoals[TwentyTwoFinancialGoals.length - 1].debt - (6000 + 2000 + 7000);
  // };

  return (
    <div css={styles.FinancesWrapper}>
      <Title>Finances</Title>
      <Title variant="h3">Baby Steps</Title>
      {babySteps.map((step, i) => <Goal key={`financial-goal-${i}`} goal={step.step} isChecked={step.achieved} />)}
      <br />

      <Title variant="h3">2022 Goals</Title>
      {twentyTwoGoals.map((g, i) => {
        return (
          <div key={`twenty-two-goal-${i}`} css={styles.GoalDebtWrapper}>
            <Goal goal={g.goal} isChecked={g.achieved} handleCheck={handleCheck} />
            <span>£{g.debt}</span>
          </div>
        );
      })}

      <br />
      <Title variant="h3">Expenses: May</Title>
      {outgoings.sort((a, b) => a.date - b.date).map((outgoing, i) => {
        return (
          <div key={`outgoing-${i}`} css={styles.GoalDebtWrapper}>
            <Goal goal={outgoing.name} date={formatDate(outgoing.date)} showCheck={false} />
            <span>£{outgoing.amount.toFixed(2)}</span>
          </div>
        );
      })}

      <br />
      <div css={styles.SummaryWrapper}>
        <Title variant="h3">Income<span>£{salary + sarahsContribution}</span></Title>
        <Title variant="h3">Expenses <span>£{totalOutgoings.toFixed(2)}</span></Title>
        <Title variant="h3">Outstanding <span>£{stillToPay.toFixed(2)}</span></Title>
        <Title variant="h3">Savings <span>£{savings.toFixed(2)}</span></Title>
        {/* <hr /> */}
        {/* <Title variant="h3">Loan Repayment <span css={styles.Excess}><br />£13,800 - (£6000 + £2000 + £7000) = {calculateLoanRepayment()}</span></Title> */}
      </div>
   </div>
  );
};