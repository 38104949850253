import { css } from "@emotion/react";
import { theme } from "./theme";

const styles = {
  AppWrapper: css`
    width: 100%;
    height: 100vh;
    background-color: ${theme.colors.white};
  `,
};

export default styles;