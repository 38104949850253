import { ITitleProps } from "./types";

export const Title = ({
  variant = "h1",
  children,
  styles
}: ITitleProps) => {
  let t = <h1 css={styles}>{children}</h1>

  switch (variant) {
    case "h2": t = <h2 css={styles}>{children}</h2>; break;
    case "h3": t = <h3 css={styles}>{children}</h3>; break
    case "h4": t = <h4 css={styles}>{children}</h4>; break
    case "h5": t = <h5 css={styles}>{children}</h5>; break
    case "h6": t = <h6 css={styles}>{children}</h6>; break
  }
  return t;
};