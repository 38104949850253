import { IOutgoing } from "../types";

export const MonthlyOutgoings: Array<IOutgoing> = [
  {
    name: 'Life Insurance',
    amount: 46.94,
    date: 1
  },
  {
    name: 'Life Insurance (Sarah)',
    amount: 12.53,
    date: 1
  },
  {
    name: 'Violin lessons',
    amount: 68.00,
    date: 1
  },
  {
    name: 'Mortgage',
    amount: 537.07,
    date: 1
  },
  {
    name: 'Phone',
    amount: 26.00,
    date: 3
  },
  {
    name: 'Internet',
    amount: 67.25,
    date: 4
  },
  {
    name: 'Investments',
    amount: 574.00,
    date: 7
  },
  {
    name: 'Dentist',
    amount: 15.14,
    date: 8
  },
  {
    name: 'Prime',
    amount: 8.99,
    date: 9
  },
  {
    name: 'Car Ins',
    amount: 34.69,
    date: 7
  },
  {
    name: 'Motorbike Ins',
    amount: 15.41,
    date: 24
  },
  {
    name: 'Netflix',
    amount: 10.99,
    date: 30
  },
  {
    name: 'Food',
    amount: 400.00,
    date: 1
  },
  {
    name: 'Fuel',
    amount: 80.00,
    date: 1
  },
  {
    name: 'Car Tax',
    amount: 14.43,
    date: 1
  },
  {
    name: 'Judo',
    amount: 20.00,
    date: 1
  },
  {
    name: 'Jujitsu',
    amount: 70.00,
    date: 1
  }
];