import { css } from "@emotion/react";

const styles = {
  LifeGoalsWrapper: css`
    padding: 2rem;
  `,
  GoalWrapper: css`
  `,
};

export default styles;