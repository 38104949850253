import { css } from "@emotion/react";

const styles = {
  LoginWrapper: css`
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    padding: 2rem 1rem;
    border-radius: 0.35rem;
    width: 100%;
    height: 100%;

    form {
      width: 15rem;
    }
  `
};

export default styles;