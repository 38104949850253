import { useState } from "react";
import { ILifeGoal } from "../../shared/types";
import { LifeTimeGoals } from "../../shared/utils/lifetime-goals";
import { Goal } from "../Goal";
import { Title } from "../Title";
import styles from "./styles";

export const LifeGoals = () => {

  const [lifeGoals] = useState<Array<ILifeGoal>>(LifeTimeGoals);

  const handleCheck = (goal: string) => {
    const index = lifeGoals.findIndex((t) => t.goal === goal);

    if (index !== -1) {
      const found = lifeGoals[index];
      found.achieved = !found.achieved;

      const arr = lifeGoals;
      arr.splice(index, 1, found);
    }
  };

  return (
    <div css={styles.LifeGoalsWrapper}>
      <Title>Life Goals</Title>
      {lifeGoals.map((g, i) => {
        return (
          <div key={`life-goal-${i}`} css={styles.GoalWrapper}>
            <Goal goal={g.goal} isChecked={g.achieved} handleCheck={handleCheck} />
          </div>
        );
      })}
    </div>
  );
};