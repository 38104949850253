import { ILifeGoal } from "../types";

// Make navigatable
export const LifeTimeGoals: Array<ILifeGoal> = [
  {
    goal: "Clear down debt (£30,000 at the start of 2022)",
    achieved: true,
  },
  {
    goal: "Pay off mortgage before 40",
    achieved: false,
  },
  {
    goal: "Join the 1500lbs club",
    achieved: false,
  },
  {
    goal: "Run a marathon",
    achieved: false,
  },
  {
    goal: "Complete an ultramarathon",
    achieved: false,
  },
  {
    goal: "Complete an Ultrabeast in under 10 hours",
    achieved: false,
  },
  {
    goal: "Perform 50 pullups withpout stopping",
    achieved: false,
  },
  {
    goal: "Perform 1000 pushups without stopping",
    achieved: false,
  },
  {
    goal: "Become a father",
    achieved: true,
  },
  {
    goal: "Learn to meditate for up to an hour",
    achieved: false,
  },
  {
    goal: "Achieve a blackbelt in Jujitsu",
    achieved: false,
  },
  {
    goal: "Tour across America on motorbike",
    achieved: false,
  },
  {
    goal: "Learn how to fly a helicopter",
    achieved: false,
  },
  {
    goal: "Learn basic medical skills",
    achieved: false,
  },
  {
    goal: "Launch a side business building websites",
    achieved: false,
  },
  {
    goal: "Become a master woodworker",
    achieved: false,
  },
  {
    goal: "Rebuild the applications of past occupations",
    achieved: false,
  },
  {
    goal: "Learn Muay Thai",
    achieved: false,
  },
  {
    goal: "Compete in a wrestling tournament",
    achieved: false,
  },
  {
    goal: "Learn electrical engineering",
    achieved: false,
  },
  {
    goal: "Redecorate the house",
    achieved: false,
  },
  {
    goal: "Invest in another property",
    achieved: false,
  },
  {
    goal: "Diversify investments into crypto, mutual, bluechip and index funds",
    achieved: false,
  },
  {
    goal: "Achieve Grade 8 in the violin",
    achieved: false,
  },
  {
    goal: "Learn how to play the piano",
    achieved: false,
  },
  {
    goal: "Learn how to play the guitar",
    achieved: false,
  },
  {
    goal: "Build a whiskey create for Alexander",
    achieved: false,
  },
  {
    goal: "Become a team lead",
    achieved: false,
  },
  {
    goal: "Become an architect",
    achieved: false,
  },
  {
    goal: "Become a CTO",
    achieved: false,
  },
  {
    goal: "Snatch 100kg",
    achieved: false,
  },
  {
    goal: "Bench press 150kg",
    achieved: false,
  },
  {
    goal: "Squat 200kg",
    achieved: false,
  },
  {
    goal: "Complete the reading list found on 52 Books",
    achieved: false,
  },
  {
    goal: "Run the New York marathon",
    achieved: false,
  },
  {
    goal: "Visit Athens",
    achieved: false,
  },
  {
    goal: "Learn how to speak mandarin",
    achieved: false,
  },
  {
    goal: "Learn how to speak japanese",
    achieved: false,
  },
  {
    goal: "Learn how to speak french",
    achieved: false,
  },
  {
    goal: "Learn how to speak spanish",
    achieved: false,
  },
  {
    goal: "Build an aquaponics farm",
    achieved: false,
  },
  {
    goal: "Build a chicken coop",
    achieved: false,
  },
  {
    goal: "Raise chickens for eggs & meat",
    achieved: false,
  },
  {
    goal: "Become a vegetarian for a month",
    achieved: false,
  },
  {
    goal: "Build a whiskey bar",
    achieved: false,
  },
  {
    goal: "Live in a different country",
    achieved: false,
  },
  {
    goal: "Learn how to repair a motorbike",
    achieved: false,
  },
  {
    goal: "Complete a masters degree in Philosophy",
    achieved: false,
  },
  {
    goal: "Read 12 books each and every year",
    achieved: false,
  },
  {
    goal: "Become a fullstack developer",
    achieved: false,
  },
  {
    goal: "Complete an education in philosophy",
    achieved: false,
  },
  {
    goal: "Learn how to draw and paint",
    achieved: false,
  },
  {
    goal: "Build a home security system with Raspberry PI",
    achieved: false,
  },
  {
    goal: "Build an automated gate",
    achieved: false,
  },
  {
    goal: "Learn how to camp",
    achieved: false,
  },
  {
    goal: "Become a cold water swimmer",
    achieved: false,
  },
  {
    goal: "Swim to Scotland",
    achieved: false,
  },
  {
    goal: "Learn how to camp",
    achieved: false,
  },
  {
    goal: "Build a NAS drive",
    achieved: false,
  },
  {
    goal: "Learn how to read the stars",
    achieved: false,
  },
  {
    goal: "Learn how to camp",
    achieved: false,
  },
  {
    goal: "Buy Dad a new motorbike",
    achieved: false,
  },
  {
    goal: "Buy Mum a trip on the Orient Express",
    achieved: false,
  },
  {
    goal: "Build a standing desk",
    achieved: false,
  },
  {
    goal: "Raise Elo rating to over 2000",
    achieved: false,
  },
  {
    goal: "Become a chess IM",
    achieved: false,
  },
  {
    goal: "Become an accomplished chef",
    achieved: false,
  },
  {
    goal: "Learn how to bake",
    achieved: false,
  }
];