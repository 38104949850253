import { GoalRealm } from "../../types";

export const TwentyTwentyThreeGoals = {
  [GoalRealm.PHYSICAL] : [
    {
      goal: "Join the 1000lbs club",
      achieved: false,
      realm: GoalRealm.PHYSICAL,
      by: "June"
    },
    {
      goal: "Run a 5km",
      achieved: false,
      realm: GoalRealm.PHYSICAL,
      by: "Start of February"
    },
    {
      goal: "Run a 10km",
      achieved: false,
      realm: GoalRealm.PHYSICAL,
      by: "Start of March"
    },
    {
      goal: "Run a half marathon",
      achieved: false,
      realm: GoalRealm.PHYSICAL,
      by: "End of April"
    },
    {
      goal: "Run a marathon",
      achieved: false,
      realm: GoalRealm.PHYSICAL,
      by: "September"
    },
  ],
  [GoalRealm.FINANCIAL] : [
    {
      goal: "Save for a new bathroom",
      achieved: false,
      realm: GoalRealm.PHYSICAL,
      by: "March - April"
    },
    {
      goal: "Save for a new kitchen",
      achieved: false,
      realm: GoalRealm.PHYSICAL,
      by: "September - October"
    },
    {
      goal: "Increase pension contributions to 15%",
      achieved: false,
      realm: GoalRealm.PHYSICAL,
      by: "May"
    },
  ],
  [GoalRealm.PROFESSIONAL] : [
    {
      goal: "Take Wallwins site live",
      achieved: false,
      realm: GoalRealm.PROFESSIONAL
    },
    {
      goal: "Take Michaels site live",
      achieved: false,
      realm: GoalRealm.PROFESSIONAL
    },
    {
      goal: "Redesign customer facing portion of my own website",
      achieved: false,
      realm: GoalRealm.PROFESSIONAL
    },
  ],
  [GoalRealm.MENTAL] : [
    {
      goal: "Complete Grade 3 in violin",
      achieved: false,
      realm: GoalRealm.MENTAL
    },
    {
      goal: "Complete Maven course",
      achieved: false,
      realm: GoalRealm.MENTAL,
      by: "March"
    },
    {
      goal: "Complete EE course",
      achieved: false,
      realm: GoalRealm.MENTAL,
      by: "September"
    },
  ],
  [GoalRealm.SPIRITUAL] : [],
  [GoalRealm.PRACTICAL] : [
    {
      goal: "Tidy up & organize shed",
      achieved: false,
      realm: GoalRealm.PRACTICAL,
      by: "End of January"
    },
    {
      goal: "Build jigs for table saw",
      achieved: false,
      realm: GoalRealm.PRACTICAL,
      by: "February"
    },
    {
      goal: "Saw-horses",
      achieved: false,
      realm: GoalRealm.PRACTICAL,
      by: "Start of January"
    },
  ],
  [GoalRealm.EMOTIONAL] : [],
  [GoalRealm.RELATIONSHIP] : []
};
