import { css } from "@emotion/react";

const styles = {
  ButtonWrapper: css`
    width: 100%;
    height: 2.5rem;
    border: 0;
    cursor: pointer;
  `,
};

export default styles;