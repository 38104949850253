import { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Finances } from './components/Finances';
import { LifeGoals } from "./components/LifeGoals";
import { Login } from './components/Login';
import { YearlyGoals } from "./components/YearlyGoals";
import { TwentyTwentyTwoGoals } from "./shared/utils/2022/yearly-goals";
import { TwentyTwentyThreeGoals } from "./shared/utils/2023/yearly-goals";
// import { useLoggedIn } from './hooks/use-logged-in';
import styles from './styles';

// const LoggedIn = () => {
//   return <p>Logged In</p>
// };

// const LoggedOut = () => {
//   return <p>Logged Out</p>
// };

const Life = () => {
  return (
    <Fragment>
      <LifeGoals />
      <hr />
      <Finances />
    </Fragment>
  );
};

function App() {

  // const [showRoutes, setShowRoutes] = useState<boolean>(false);
  // const isLoggedIn = useLoggedIn(false);

  // setTimeout(() => {
  //   setShowRoutes(true);
  // }, theme.animationTiming.intro);

  return (
    <div css={styles.AppWrapper}>
      {/* <Header /> */}
      {/* {!showRoutes && <Logo />} */}
      {/* {showRoutes && ( */}
        <Router>
          <Routes>
            {/* <Route path="/" element={isLoggedIn ? <LoggedIn /> : <LoggedOut />} /> */}
            <Route path="/" element={<Life />} />
            <Route path="/2022" element={<YearlyGoals year="2022" goals={TwentyTwentyTwoGoals} />} />
            <Route path="/2023" element={<YearlyGoals year="2023" goals={TwentyTwentyThreeGoals} />} />
            <Route path="/finances" element={<Finances />} />
            <Route path="/life-goals" element={<LifeGoals />} />
            <Route path="/log-me-in" element={<Login />} />
          </Routes>
        </Router>
      {/* )} */}
    </div>
  );
}

export default App;
