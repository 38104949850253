import { css } from "@emotion/react";

const styles = {
  GoalWrapper: css`
    display: flex;
    align-items: center;
  `,
  Checkbox: css`
    width: 1rem;
    height: 1rem;
    border: 1px solid black;
    border-radius: 0.25rem;
    display: inline-block;
    margin-right: 0.25rem;
    cursor: pointer;
  `,
  Checked: (checked: boolean) => {
    if (checked) {
      return css`
        background-color: #2196F3;
        border: none;
        position: relative;

        :after {
          content: "";
          position: absolute;
          left: 5px;
          top: 1px;
          width: 3px;
          height: 8px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      `;
    } else {
      return css`
        background-color: #eee;
        border: none;
      `;
    }
  },
  Goal: (checked: boolean) => {
    return css`
      text-decoration: ${checked ? "line-through" : "none"};
    `;
  },
  Date: css`
    position: absolute;
    left: 55%;

    @media (min-width: 768px) {
      left: 25%;
    }
  `,
};

export default styles;