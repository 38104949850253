import { css } from "@emotion/react";

const styles = {
  InputWrapper: css`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 1rem;

    input {
      border: 1px solid grey;
      border-radius: 0.25rem;
      height: 2.25rem;
    }
  `,
  Label: css`
    padding-bottom: 0.5rem;
  `,
};

export default styles;