import { IYearlyFinancialGoal } from "../../types";

export const TwentyTwoFinancialGoals: Array<IYearlyFinancialGoal> = [{
  goal: "Clear sun room loan",
  debt: 7500.00,
  achieved: true,
},
{
  goal: "Clear student loan",
  debt: 5700.00,
  achieved: true,
},
{
  goal: "Payoff TV",
  debt: 800.00,
  achieved: true,
},
{
  goal: "Clear credit card",
  debt: 1000.00,
  achieved: true,
},
{
  goal: "Clear car loan (settlement)",
  debt: 13800.00,
  achieved: true,
}]