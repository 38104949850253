export enum GoalRealm {
  PHYSICAL = 'Physical',
  MENTAL = 'Mental',
  EMOTIONAL = 'Emotional',
  FINANCIAL = 'Financial',
  PRACTICAL = 'Practical',
  PROFESSIONAL = 'Professional',
  SPIRITUAL = 'Spiritual',
  RELATIONSHIP = 'Relationship'
}

export interface IYearlyFinancialGoal {
  goal: string;
  debt: number;
  achieved: boolean;
}

export interface IOutgoing {
  name: string;
  amount: number;
  date: number;
}

export interface IYearlyGoal {
  goal: string;
  achieved: boolean;
  realm: GoalRealm;
  by?: string;
}

export interface ILifeGoal {
  goal: string;
  achieved: boolean;
}