import { Goal } from "../Goal";
import { Title } from "../Title";
import styles from "./styles";
import { IYearlyGoalsProps } from "./types";

export const YearlyGoals = ({
  year,
  goals,
}: IYearlyGoalsProps) => {
  const handleCheck = () => {};

  return (
    <div css={styles.YearlyGoalsWrapper}>
      <Title>{year} Goals</Title>
      
      {Object.keys(goals).map((realm) => {
        return (
          <div key={`yearly-goal-${realm}`}>
            <Title variant="h3">{realm}</Title>
            {
              goals[realm].map((g, i) => <Goal key={`${year}-yearly-goal-${i}`} goal={g.goal} isChecked={g.achieved} handleCheck={handleCheck} />)
            }
          </div>
        );
      })}
    </div>
  );
};
